<template>
  <b-form class="provider-tab-account">
    <b-row>
      <b-col
        v-for="(item, index) of bankTemplate"
        :key="index"
        cols="12"
        md="4"
      >
        <b-form-group :label="item.label">
          <b-form-input
            v-model="item.bankdetails.keyvalue"
            :type="item.type === 'VARCHAR' ? 'text' : 'number'"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
        class="my-auto"
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveBankDetails"
        >
          {{ $t('Save Changes') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from '../useProvider'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    bankTemplate: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    saveBankDetails() {
      const formData = new FormData()
      this.bankTemplate.forEach((element, index) => {
        formData.append(`bankform_id[${index}]`, element.id)
        formData.append(`keyvalue[${index}]`, element.bankdetails.keyvalue)
        formData.append(`id[${index}]`, element.bankdetails.id)
      })
      showLoader()
      const { editBankDetails, addBankDetails } = useProviderUi()
      const detailType = this.bankTemplate.find(x => x.bankdetails.id) ? editBankDetails : addBankDetails
      detailType(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style></style>
