<template>
  <b-form class="provider-tab-social">
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-facebook.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.facebook"
                name="facebook"
                type="text"
                :placeholder="$t('Facebook (Your Business Facebook Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.facebook != '' && userData.facebook != null"
                :disabled="userData.facebook == '' || userData.facebook == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-whatsapp.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.whatsapp"
                name="whatsapp"
                type="text"
                :placeholder="$t('Whatsapp (Your Business Whatsapp Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.whatsapp != '' && userData.whatsapp != null"
                :disabled="userData.whatsapp == '' || userData.whatsapp == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-instagram.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.instagram"
                name="instagram"
                type="text"
                :placeholder="$t('Instagram (Your Business Instagram Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.instagram != '' && userData.instagram != null"
                :disabled="userData.instagram == '' || userData.instagram == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-telegram-app.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.telegram"
                name="telegram"
                type="text"
                :placeholder="$t('Telegram (Your Business Telegram Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.telegram != '' && userData.telegram != null"
                :disabled="userData.telegram == '' || userData.telegram == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-linkedin.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.linkedin"
                name="linkedin"
                type="text"
                :placeholder="$t('Linkedin (Your Business Linkedin Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.linkedin != '' && userData.linkedin != null"
                :disabled="userData.linkedin == '' || userData.linkedin == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-twitter.png')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.twitter"
                name="twitter"
                type="text"
                :placeholder="$t('Twitter (Your Business Twitter Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.twitter != '' && userData.twitter != null"
                :disabled="userData.twitter == '' || userData.twitter == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-facebook-messenger.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.messenger"
                name="messenger"
                type="text"
                :placeholder="$t('Messenger (Your Business Messenger Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.messenger != '' && userData.messenger != null"
                :disabled="userData.messenger == '' || userData.messenger == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-tiktok.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.tiktok"
                name="tiktok"
                type="text"
                :placeholder="$t('TikTok (Your Business TikTok Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.tiktok != '' && userData.tiktok != null"
                :disabled="userData.tiktok == '' || userData.tiktok == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-viber.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.viber"
                name="viber"
                type="text"
                :placeholder="$t('Viber (Your Business Viber Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.viber != '' && userData.viber != null"
                :disabled="userData.viber == '' || userData.viber == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-youtube.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.youtube"
                name="youtube"
                type="text"
                :placeholder="$t('YouTube (Your Business YouTube Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.youtube != '' && userData.youtube != null"
                :disabled="userData.youtube == '' || userData.youtube == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-pinterest.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.pintrest"
                name="pintrest"
                type="text"
                :placeholder="$t('Pinterest (Your Business Pinterest Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.pintrest != '' && userData.pintrest != null"
                :disabled="userData.pintrest == '' || userData.pintrest == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>


      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <b-row class="d-flex justify-content-center align-items-center">
            <div>
              <img :src="require('@/assets/images/icons/social/icons8-skype.svg')" height="40">
            </div>
            <div class="col-9">
              <b-form-input
                v-model="userData.skype"
                name="skype"
                type="text"
                :placeholder="$t('Skype (Your Business Skype Link)')"
              />
            </div>
            <div class="d-md-inline-block d-none">
              <b-form-checkbox
                switch
                inline
                :checked="userData.skype != '' && userData.skype != null"
                :disabled="userData.skype == '' || userData.skype == null"
                size="md"
                class="mr-0 ml-0"
              />
            </div>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="my-auto text-center"
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$emit('save-details')"
        >
          {{ $t('Save Changes') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style></style>
